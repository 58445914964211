import React from "react";
import "./projects.css";
import bullbearwhale from "./assets/bullbearwhale.png";
import comein from "./assets/comein.png";
//import durple from "./assets/durple.png";
//import kangaroo from "./assets/kangaroo.png";
//import purps from "./assets/purps.png";
import supercardgame from "./assets/supercardgame.png";
//import meepy from "./assets/meepy.png";
//import monkey from "./assets/monkey.png";

export default function Projects() {
  return (
    <>
        <div className="projects-container" id="portfolio">
        <h1 className="portfolio">Projects</h1>
        <div className="projects">
      {/*
          <Project
            image={monkey}
            title="Monkey Protocol"
            short="Decentralized web game"
            link="https://www.figma.com/file/VvdOyjtYxvxLpiwsuihDzz/Monkey-Protocol?node-id=0%3A1"
            tags="figma, typescript, react"
          />
            <Project
            image={meepy}
            title="Youtube Channel"
            short="Motion graphics"
            link="https://www.youtube.com/channel/UC5rFFpqwo5VbyxLcV8qxXRw/featured"
            tags="photoshop, illustrator, premiere pro, after effects"
          />
            <Project
            image={purps}
            title="Purps Revolution"
            short="Solo Indie Game"
            link="https://hushhush4350.itch.io/purps-revolution"
            tags="gms2, fl-studio"
          />
      */}
          <Project
            image={supercardgame}
            title="Super Card Game"
            short="Decentralized web game"
            link="https://showcase.ethglobal.com/ethonline2021/super-card-game"
            tags="typescript, react, blender"
          />
          <Project
            image={bullbearwhale}
            title="Bull Bear Whale"
            short="Procedurally generated NFT's"
            link="https://showcase.ethglobal.com/web3jam/bull-bear-whale"
            tags="node.js, typescript, photoshop"
          />
          <Project
            image={comein}
            title="Come In"
            short="Music video"
            link="https://www.youtube.com/watch?v=WIxhU3fm_mw"
            tags="photoshop, illustrator, after effects"
          />
          {/*
          <Project
            image={kangaroo}
            title="KangarooBot.xyz"
            short="Static website"
            link="https://kangaroobot.xyz/"
            tags="adobe illustrator, html, css"
          />
          <Project
            image={durple}
            title="Durple"
            short="Decentralized social media"
            link="https://showcase.ethglobal.com/ethonline/durple"
            tags="react"
          /> */}
        </div>
      </div>
    </>
  );
}

function Project(props) {
  return (
    <>
      <div className="project">
        <a href={props.link} target="blank">
          <img src={props.image} alt={props.title} />
            <div></div>
            <h2>{props.title}</h2>
            <h3>{props.short}</h3>
            <h4>{props.tags}</h4>
        </a>

      </div>
    </>
  );
}
