import React from "react";
import DrawBlob from "blob-animated";
import { useEffect } from "react";
import "./home.css";

//yellow ffd021

export default function Home() {
  useEffect(() => {
    new DrawBlob({
      canvas: document.getElementsByClassName("blob1")[0],
      speed: 400,
      scramble: 0.1,
      color: "#222222",
    });
    new DrawBlob({
      canvas: document.getElementsByClassName("blob2")[0],
      speed: 400,
      scramble: 0.07,
      color: "#ffd021",
    });
    new DrawBlob({
      canvas: document.getElementsByClassName("blob3")[0],
      speed: 400,
      scramble: 0.15,
      color: "#e34427",
    });
  }, []);
  return (
    <>
      <div className="intro">
        <h1>Hi, I'm Justin.</h1>
        <div className="quick-links">
          <a href="#portfolio">Projects</a>
    {/*<a href="https://github.com/kaStinju" target="blank">
            Github
          </a>*/}
        </div>
      </div>
      <div className="blobs">
        <canvas className="blob2"></canvas>
        <canvas className="blob1"></canvas>
        <canvas className="blob3"></canvas>
      </div>
    </>
  );
}
