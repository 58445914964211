import Home from "./Home";
import Projects from "./projects";
import { useEffect } from "react";

function App() {
  //f16323 orange
  //f2f3f4 greywhite
  //ffd021 yellow
  //e34427 red
  useEffect(() => {
    document.title = "Portfolio | Justin";
  }, []);
  return (
    <>
      <Home />
      <div className="fill"></div>
      <Projects />
    </>
  );
}

export default App;
